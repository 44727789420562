<template>
  <page-header-wrapper>
    <!--    <template v-slot:content>-->
    <!--      <div class="page-header-content">-->
    <!--        <div class="avatar">-->
    <!--          <a-avatar size="large" :src="avatar"/>-->
    <!--        </div>-->
    <!--        <div class="content">-->
    <!--          <div class="content-title">-->
    <!--            {{ timeFix }}，{{ nickname }}<span class="welcome-text"></span>-->
    <!--          </div>-->
    <!--          &lt;!&ndash;<div> {{ postGroup }} | {{ user.dept&&user.dept.deptName }}</div>&ndash;&gt;-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </template>-->


    <div style="width: 100%;display: flex;" v-if="userInfo.adminFlag">
      <div style="width: 100%;">
        <template>
          <div class="page-header-index-wide">

            <a-row :gutter="24" style="margin-left: 0.5%;margin-right: 0.5%">
              <!--              <a-col :sm="18" :md="10" :xl="4" :style="{ marginBottom: '24px' }">-->
              <!--                <chart-card title="用户总数量" :total="totalNum">-->
              <!--                  <template slot="footer"><span>用户总数量</span></template>-->
              <!--                </chart-card>-->
              <!--              </a-col>-->


              <a-col :sm="6" :md="6" :xl="6" :style="{ marginBottom: '24px'}">
                <a-card style="width: 100%;border-radius: 5%">
                  <p v-if="userFlag=='a'">{{ '今日   |   新增用户数' }}</p>
                  <p v-if="userFlag=='a'">{{ this.data(1) }}</p>
                  <p v-if="userFlag=='b'">{{ '昨日   |   新增用户数' }}</p>
                  <p v-if="userFlag=='b'">{{ this.data(2) }}</p>
                  <p v-if="userFlag=='c'">{{ '近七日   |   新增用户数' }}</p>
                  <p v-if="userFlag=='c'">{{ this.data(3) }}</p>
                  <p><font size="6px">{{ userNum }}</font></p>
                  <a-radio-group default-value="a" @change="(e) => updateUser(e.target.value)" button-style="solid">
                    <a-radio-button value="a">
                      今日
                    </a-radio-button>
                    <a-radio-button value="b">
                      昨日
                    </a-radio-button>
                    <a-radio-button value="c">
                      近七日
                    </a-radio-button>
                  </a-radio-group>
                </a-card>
              </a-col>


              <a-col :sm="6" :md="6" :xl="6" :style="{ marginBottom: '24px' }">
                <a-card style="width: 100%;border-radius: 5%">
                  <p v-if="diominFlag=='a'">{{ '今日   |   累计充值钻石收入' }}</p>
                  <p v-if="diominFlag=='a'">{{ this.data(1) }}</p>
                  <p v-if="diominFlag=='b'">{{ '昨日   |   累计充值钻石收入' }}</p>
                  <p v-if="diominFlag=='b'">{{ this.data(2) }}</p>
                  <p v-if="diominFlag=='c'">{{ '近七日   |   累计充值钻石收入' }}</p>
                  <p v-if="diominFlag=='c'">{{ this.data(3) }}</p>
                  <p><font size="6px">{{ totalNum }}{{'$'}}</font></p>
                  <a-radio-group default-value="a" @change="(e) => updateDiomn(e.target.value)" button-style="solid">
                    <a-radio-button value="a">
                      今日
                    </a-radio-button>
                    <a-radio-button value="b">
                      昨日
                    </a-radio-button>
                    <a-radio-button value="c">
                      近七日
                    </a-radio-button>
                  </a-radio-group>
                </a-card>
              </a-col>


              <a-col :sm="6" :md="6" :xl="6" :style="{ marginBottom: '24px' }">
                <a-card style="width: 100%;border-radius: 5%">
                  <p v-if="vipFlag=='a'">{{ '今日   |   累计购买会员收入' }}</p>
                  <p v-if="vipFlag=='a'">{{ this.data(1) }}</p>
                  <p v-if="vipFlag=='b'">{{ '昨日   |   累计购买会员收入' }}</p>
                  <p v-if="vipFlag=='b'">{{ this.data(2) }}</p>
                  <p v-if="vipFlag=='c'">{{ '近七日   |   累计购买会员收入' }}</p>
                  <p v-if="vipFlag=='c'">{{ this.data(3) }}</p>
                  <p><font size="6px">{{ manNum }}{{'$'}}</font></p>
                  <a-radio-group default-value="a" @change="(e) => updateVip(e.target.value)" button-style="solid">
                    <a-radio-button value="a">
                      今日
                    </a-radio-button>
                    <a-radio-button value="b">
                      昨日
                    </a-radio-button>
                    <a-radio-button value="c">
                      近七日
                    </a-radio-button>
                  </a-radio-group>
                </a-card>
              </a-col>


              <a-col :sm="6" :md="6" :xl="6" :style="{ marginBottom: '24px' }">
                <a-card style="width: 100%;border-radius: 5%">
                  <p v-if="buyFlag=='a'">{{ '今日   |   累计购买剧集收入' }}</p>
                  <p v-if="buyFlag=='a'">{{ this.data(1) }}</p>
                  <p v-if="buyFlag=='b'">{{ '昨日   |   累计购买剧集收入' }}</p>
                  <p v-if="buyFlag=='b'">{{ this.data(2) }}</p>
                  <p v-if="buyFlag=='c'">{{ '近七日   |   累计购买剧集收入' }}</p>
                  <p v-if="buyFlag=='c'">{{ this.data(3) }}</p>
                  <p><font size="6px">{{ '钻石数：' + dionNum }}</font></p>
                  <a-radio-group default-value="a" @change="(e) => updateBuy(e.target.value)" button-style="solid">
                    <a-radio-button value="a">
                      今日
                    </a-radio-button>
                    <a-radio-button value="b">
                      昨日
                    </a-radio-button>
                    <a-radio-button value="c">
                      近七日
                    </a-radio-button>
                  </a-radio-group>
                </a-card>
              </a-col>
            </a-row>


            <a-row :gutter="24">
              <div style="display:flex;justify-content: space-around">
                <div id="myChart" :style="{width: '45%', height: '250%'}" style="background-color: white;border-radius: 5%"></div>
                <div id="myChart4" :style="{width: '45%', height: '250%'}" style="background-color: white;border-radius: 5%"></div>
              </div>
            </a-row>


            <a-row :gutter="24">
              <div style="display:flex;justify-content: space-around;">
                <div id="myChart1" style="width: 45%;margin-top: 1%;background-color: white;border-radius: 5%" :style="{height: '400%'}"></div>
                <div id="myChart3" style="width: 45%;margin-top: 1%;background-color: white;border-radius: 5%" :style="{height: '400%'}"></div>
              </div>
            </a-row>


            <!--            <a-row :gutter="24">-->
            <!--              <div id="myChart4" :style="{width: '100%', height: '300px'}"></div>-->
            <!--            </a-row>-->
            <!--            <a-row :gutter="24">-->
            <!--              <div>-->
            <!--&lt;!&ndash;                <a-col style="float: left">&ndash;&gt;-->
            <!--&lt;!&ndash;                  <div id="myChart3" :style="{width: '800px', height: '300px'}"></div>&ndash;&gt;-->
            <!--&lt;!&ndash;                </a-col>&ndash;&gt;-->
            <!--                <a-col style="float: left">-->
            <!--                  <div id="myChart4" :style="{width: '800px', height: '300px'}"></div>-->
            <!--                </a-col>-->
            <!--              </div>-->
            <!--            </a-row>-->

          </div>
        </template>
      </div>
    </div>
    <div v-else  style="width: 100%;">
      <div style="width: 100%;">
        <template>
          <div class="page-header-index-wide">

            <a-row :gutter="24" style="margin-left: 0.5%;margin-right: 0.5%">

              <a-col :sm="6" :md="6" :xl="6" :style="{ marginBottom: '24px'}">
                <a-card style="width: 100%">
                  <p v-if="userFlag=='a'">{{ '今日   |   新增下级用户数' }}</p>
                  <p v-if="userFlag=='a'">{{ this.data(1) }}</p>
                  <p v-if="userFlag=='b'">{{ '昨日   |   新增下级用户数' }}</p>
                  <p v-if="userFlag=='b'">{{ this.data(2) }}</p>
                  <p v-if="userFlag=='c'">{{ '近七日   |   新增下级用户数' }}</p>
                  <p v-if="userFlag=='c'">{{ this.data(3) }}</p>
                  <p><font size="6px">{{ disUserNum }}</font></p>
                  <a-radio-group default-value="a" @change="(e) => updateDisUser(e.target.value)" button-style="solid">
                    <a-radio-button value="a">
                      今日
                    </a-radio-button>
                    <a-radio-button value="b">
                      昨日
                    </a-radio-button>
                    <a-radio-button value="c">
                      近七日
                    </a-radio-button>
                  </a-radio-group>
                </a-card>
              </a-col>


              <a-col :sm="6" :md="6" :xl="6" :style="{ marginBottom: '24px' }">
                <a-card style="width: 100%">
                  <p v-if="diominFlag=='a'">{{ '今日   |   累计佣金收入' }}</p>
                  <p v-if="diominFlag=='a'">{{ this.data(1) }}</p>
                  <p v-if="diominFlag=='b'">{{ '昨日   |   累计佣金收入' }}</p>
                  <p v-if="diominFlag=='b'">{{ this.data(2) }}</p>
                  <p v-if="diominFlag=='c'">{{ '近七日   |   累计佣金收入' }}</p>
                  <p v-if="diominFlag=='c'">{{ this.data(3) }}</p>
                  <p><font size="6px">{{ disTotalNum }}{{'$'}}</font></p>
                  <a-radio-group default-value="a" @change="(e) => updateDisDiomn(e.target.value)" button-style="solid">
                    <a-radio-button value="a">
                      今日
                    </a-radio-button>
                    <a-radio-button value="b">
                      昨日
                    </a-radio-button>
                    <a-radio-button value="c">
                      近七日
                    </a-radio-button>
                  </a-radio-group>
                </a-card>
              </a-col>

              <a-col :sm="6" :md="6" :xl="6" :style="{ marginBottom: '24px' }">
                <a-card style="width: 100%">
                  <p v-if="orderFlag=='a'">{{ '今日   |   下级用户充值订单数' }}</p>
                  <p v-if="orderFlag=='a'">{{ this.data(1) }}</p>
                  <p v-if="orderFlag=='b'">{{ '昨日   |   下级用户充值订单数' }}</p>
                  <p v-if="orderFlag=='b'">{{ this.data(2) }}</p>
                  <p v-if="orderFlag=='c'">{{ '近七日   |   下级用户充值订单数' }}</p>
                  <p v-if="orderFlag=='c'">{{ this.data(3) }}</p>
                  <p><font size="6px">{{ orderNum }}{{'单'}}</font></p>
                  <a-radio-group default-value="a" @change="(e) => updateOrderDiomn(e.target.value)" button-style="solid">
                    <a-radio-button value="a">
                      今日
                    </a-radio-button>
                    <a-radio-button value="b">
                      昨日
                    </a-radio-button>
                    <a-radio-button value="c">
                      近七日
                    </a-radio-button>
                  </a-radio-group>
                </a-card>
              </a-col>


              <a-col :sm="6" :md="6" :xl="6" :style="{ marginBottom: '24px' }">
                <a-card style="width: 100%">
                  <p v-if="rechargeFlag=='a'">{{ '今日   |   下级用户充值金额' }}</p>
                  <p v-if="rechargeFlag=='a'">{{ this.data(1) }}</p>
                  <p v-if="rechargeFlag=='b'">{{ '昨日   |   下级用户充值金额' }}</p>
                  <p v-if="rechargeFlag=='b'">{{ this.data(2) }}</p>
                  <p v-if="rechargeFlag=='c'">{{ '近七日   |   下级用户充值金额' }}</p>
                  <p v-if="rechargeFlag=='c'">{{ this.data(3) }}</p>
                  <p><font size="6px">{{ rechargeNum }}{{'$'}}</font></p>
                  <a-radio-group default-value="a" @change="(e) => updateRechargeDiomn(e.target.value)" button-style="solid">
                    <a-radio-button value="a">
                      今日
                    </a-radio-button>
                    <a-radio-button value="b">
                      昨日
                    </a-radio-button>
                    <a-radio-button value="c">
                      近七日
                    </a-radio-button>
                  </a-radio-group>
                </a-card>
              </a-col>



            </a-row>


            <a-row :gutter="24">
              <div style="display:flex;justify-content: space-around;">
                <div id="myChart5" :style="{width: '50%', height: '250%'}"></div>
                <div id="myChart6" :style="{width: '50%', height: '250%'}"></div>
              </div>
            </a-row>


            <a-row :gutter="24" v-if="userInfo.roleList[0].id!=16">
              <div style="display:flex;justify-content: space-around;">
                <div id="myChart7" style="width: 50%" :style="{height: '400%'}"></div>
                <div id="myChart8" style="width: 50%" :style="{height: '400%'}"></div>
              </div>
            </a-row>
          </div>
        </template>
      </div>
    </div>

  </page-header-wrapper>
</template>

<script>
import {timeFix} from '@/utils/util'
import {mapGetters} from 'vuex'
import {PageHeaderWrapper} from '@/components/ProLayout'
import ruoyiLogo from '@/assets/projects/ruoyi.png'
import antdvLogo from '@/assets/projects/antdv.png'
import * as echarts from 'echarts';
import {
  getInstutionByDay,
  getBuyVipData,
  getUserData,
  getRechargePal,
  getDisRechargePal,
  getALLCollectNum,
  getDiomnData,
  updateDisDiomn,
  updateOrderDiomn,
  updateRechargeDiomn,
  getVipData,
  getDisUserData,
  getRuioDataByDay,
  getDisInstutionByDay,
  getMoneyInstutionByDay,
  getMoneyRechargePal,
  getBuyData
} from '@/api/system/user'
import ChartCard from './ChartCard'
import moment from 'moment';
import {getSmsTotalBarChart, getSmsTotalPieChart} from "@/api/sms/log";

export default {
  name: 'Index',
  components: {
    PageHeaderWrapper,
    ChartCard
  },
  data() {
    return {
      xInstutiondata: [],
      yInstutiondata1: [],

      xInstutiondata1s: [],
      yInstutiondata1s: [],

      xInstutiondata4: [],
      yInstutiondata4: [],
      yInstutiondata4s: [],


      yInstutiondata2: [],
      userFlag: 'a',
      DisUserFlag: 'a',
      diominFlag: 'a',
      orderFlag: 'a',
      rechargeFlag: 'a',
      disDiominFlag: 'a',
      vipFlag: 'a',
      disVipFlag: 'a',
      buyFlag: 'a',
      DisbuyFlag: 'a',
      xStroredata: [],
      yStroredata1: [],
      yStroredata2: [],

      xUserdata: [],
      yUserdata1: [],
      yUserdata2: [],
      rechargeData: [],
      disRechargeData: [],
      moneyRechargeData: [],

      xdevicedata: [],
      ydevicedata1: [],
      ydevicedata2: [],

      userDayOneData: [],
      userDayTwoData: [],

      xDisInstutiondata:[],
      yDisInstutiondata1:[],

      xMoneyInstutiondata:[],
      yMoneyInstutiondata1:[],
      totalNum: "0",
      disTotalNum: "0",
      orderNum: "0",
      rechargeNum: "0",
      userNum: "0",
      disUserNum: "0",
      noNum: "0",
      manNum: "0",
      womanNum: "0",
      dionNum: "0",
      instutionList: [],
      storeList: [],
      loading: false,
      // 贡献者
      contributors: [
        {
          name: 'wangze',
          avatar: 'https://portrait.gitee.com/uploads/avatars/user/1662/4988475_fuzui_1586973704.png!avatar60',
          email: '73400@163.com'
        }
      ],
      // 赞助
      sponsorList: [
        {
          key: 'RuoYi',
          tab: 'RuoYi'
        },
        {
          key: 'Antdv',
          tab: 'Antdv'
        }
      ],
      noTitleKey: 'RuoYi',
      timeFix: timeFix(),
      // 用户信息
      user: {
        dept: {
          deptName: ''
        }
      },
      roleGroup: {},
      postGroup: {},
      // 致谢项目
      projects: [
        {
          logo: ruoyiLogo,
          name: 'RuoYi-Vue',
          description: '基于SpringBoot，Spring Security，JWT，Vue & Element 的前后端分离权限管理系统。',
          website: 'http://ruoyi.vip',
          downloadUrl: 'https://gitee.com/y_project/RuoYi-Vue'
        },
        {
          logo: antdvLogo,
          name: 'Ant Design Vue',
          description: 'An enterprise-class UI components based on Ant Design and Vue. ',
          website: 'https://antdv.com',
          downloadUrl: 'https://github.com/vueComponent/ant-design-vue/'
        },
        {
          logo: antdvLogo,
          name: 'Antd Pro Vue',
          description: 'An out-of-box UI solution for enterprise applications as a Vue boilerplate. based on Ant Design of Vue.',
          website: 'https://pro.antdv.com',
          downloadUrl: 'https://github.com/vueComponent/ant-design-vue/'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'avatar',
      'nickname',
        'userInfo'
    ])
  },
  created() {
    this.getUser()
    // if (!this.userInfo.adminFlag) {
      this.updateUser('a')
      this.updateDiomn('a')
      this.updateDisDiomn('a')
      this.updateOrderDiomn('a')
      this.updateRechargeDiomn('a')
      this.updateVip('a')
      this.updateBuy('a')
    // }

    this.drawLine1()

    // this.getALLCollection()
  },
  mounted() {



    this.drawLine1();
    this.drawLine2();
    this.drawLine3();
    this.drawLine4();
    if (!this.userInfo.adminFlag) {
      this.drawLine5();
      this.drawLine6();
      this.drawLine7();
      this.drawLine8();
    }
    if (!this.userInfo.adminFlag) {
      this.updateDisDiomn('a')
      this.updateOrderDiomn('a')
      this.updateRechargeDiomn('a')
      this.updateDisUser('a')
    }
    this.updateUser('a')
    this.updateDiomn('a')



    this.updateVip('a')
    this.updateBuy('a')

  },
  methods: {
    updateUser(value) {
      console.log(value)
      this.userFlag = value
      getUserData({
        type: value
      }).then(res => {
        const data = res.data;
        this.userNum = data + "";
      });
    },
    updateDisUser(value) {
      console.log(value)
      this.DisUserFlag = value
      getDisUserData({
        type: value
      }).then(res => {
        const data = res.data;
        this.disUserNum = data + "";
      });
    },
    updateDiomn(value) {
      this.diominFlag = value
      getDiomnData({
        type: value
      }).then(res => {
        const data = res.data;
        this.totalNum = data + "";
      });
    },


    updateDisDiomn(value) {
      this.disDiominFlag = value
      updateDisDiomn({
        type: value
      }).then(res => {
        const data = res.data;
        this.disTotalNum = data + "";
      });
    },


    updateOrderDiomn(value) {
      this.orderFlag = value
      updateOrderDiomn({
        type: value
      }).then(res => {
        const data = res.data;
        this.orderNum = data + "";
      });
    },

    updateRechargeDiomn(value) {
      this.rechargeFlag = value
      updateRechargeDiomn({
        type: value
      }).then(res => {
        const data = res.data;
        this.rechargeNum = data + "";
      });
    },

    updateVip(value) {
      this.vipFlag = value
      getVipData({
        type: value
      }).then(res => {
        const data = res.data;
        this.manNum = data + "";
      });
    },
    updateBuy(value) {
      this.buyFlag = value
      getBuyData({
        type: value
      }).then(res => {
        const data = res.data;

        data.forEach((el) => {
          if (el.type == '1') {
            this.womanNum = el.price + "";
          }
          if (el.type == '2') {
            this.dionNum = el.price + "";
          }
        });
      });
    },
    // 获取用户信息
    getUser() {
      getUserProfile().then(response => {
        this.user = response.data
        this.roleGroup = response.roleGroup
        this.postGroup = response.postGroup
      })
    },
    data(value) {
      if (value === 1) {
        var today = new Date();
        var year = today.getFullYear();//获取年份
        var month = today.getMonth() + 1;//获取月份
        var day = today.getDate();//获取日期
        return year + "-" + month + "-" + day + '~' + year + "-" + month + "-" + day
      }
      if (value === 2) {
        var today = new Date();
        var yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
        return yesterday.getFullYear() + "-" + (yesterday.getMonth() + 1) + "-" + yesterday.getDate() + '~' + yesterday.getFullYear() + "-" + (yesterday.getMonth() + 1) + "-" + yesterday.getDate()
      }

      if (value === 3) {
        var today = new Date();
        var yesterday = new Date(today.getTime() - 144 * 60 * 60 * 1000);
        var yesterdays = new Date(today.getTime());
        return yesterday.getFullYear() + "-" + (yesterday.getMonth() + 1) + "-" + yesterday.getDate() + '~' + yesterdays.getFullYear() + "-" + (yesterdays.getMonth() + 1) + "-" + yesterdays.getDate()
      }
    },


    onSponsorTabChange(key, type) {
      this[type] = key
    },
    collectionCount() {
      getInstutionByDay().then(res => {
        if (res.data) {
          const data = res.data;
          data.forEach((el) => {
            const obj = {}
            obj.num = el.price;
            obj.title = el.times;
            this.instutionList.push(obj);
          });
        }
      });
      // /*门店日增*/
      // getStoreByDay().then(res => {
      //   if (res.data){
      //     const data = res.data;
      //     data.forEach((el) => {
      //       const obj = {}
      //       obj.num = el.num;
      //       obj.title = el.time;
      //       this.storeList.push(obj);
      //     });
      //   }
      // });
    },
    getALLCollection() {
      //统计（机构总数量、门店总数量、用户总数量、设备总数量）
      getALLCollectNum().then(res => {
        if (res.data) {
          const data = res.data;
          this.totalNum = data.totalNum + "";
          this.userNum = data.userNum + "";
          this.noNum = data.noNum + "";
          this.manNum = data.manNum + "";
          this.womanNum = data.womanNum + "";
        }
      });
    },

    drawLine1() {
      //获取机构概览
      getInstutionByDay().then(res => {
        this.xInstutiondata = [];
        this.yInstutiondata1 = [];
        this.xInstutiondata1s = [];
        this.yInstutiondata1s = [];
        if (res.data) {
          const data = res.data.orderNum;//  订单量
          data.forEach((el) => {
            this.xInstutiondata.push(el.times);
            this.yInstutiondata1.push(Number(el.nums));
          });
          res.data.orderMoney.forEach((el) => {
            // this.xInstutiondata1s.push(el.times);
            this.yInstutiondata1s.push(Number(el.moneys));
          });
          // debugger
          // console.log(this.xInstutiondata)
          // data1.forEach((el) => {
          //   this.yInstutiondata2.push(Number(el.num));
          // });

          // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例  myChart1
          let myChart = echarts.init(document.getElementById('myChart'));
          var option = {
            title: {
              text: '  用户充值概览'
            },
            tooltip: {
              trigger: 'item',
              triggerOn: "mousemove",
            },
            legend: {
              data: ['充值收入', '充值订单数']
            },


            grid: {
              left: '3%',
              right: '6%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: this.xInstutiondata
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                name: '充值订单数',
                type: 'line',
                stack: 'Total',
                data: this.yInstutiondata1
              },
              {
                name: '充值收入',
                type: 'line',
                stack: 'Total',
                data: this.yInstutiondata1s
              }
            ]
          };
          // 绘制图表
          // myChart.setOption(option);
          option && myChart.setOption(option);
        }
      });

    },


    drawLine5() {
      //获取机构概览
      getDisInstutionByDay().then(res => {
        this.xDisInstutiondata = [];
        this.yDisInstutiondata = [];
        if (res.data) {
          const data = res.data;//
          data.forEach((el) => {
            this.xDisInstutiondata.push(el.times);
            this.yDisInstutiondata.push(Number(el.nums));
          });

          // debugger
          // console.log(this.xInstutiondata)
          // data1.forEach((el) => {
          //   this.yInstutiondata2.push(Number(el.num));
          // });

          // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例  myChart1
          let myChart = echarts.init(document.getElementById('myChart5'));
          var option = {
            title: {
              text: '  下级用户充值概览'
            },
            tooltip: {
              trigger: 'item',
              triggerOn: "mousemove",
            },
            legend: {
              data: ['下级用户充值金额']
            },


            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: this.xDisInstutiondata
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                name: '充值订单数',
                type: 'line',
                stack: 'Total',
                data: this.yDisInstutiondata
              }
            ]
          };
          // 绘制图表
          // myChart.setOption(option);
          option && myChart.setOption(option);
        }
      });

    },


    drawLine6() {
      //获取机构概览
      getMoneyInstutionByDay().then(res => {
        this.xMoneyInstutiondata = [];
        this.yMoneyInstutiondata = [];
        if (res.data) {
          const data = res.data;//
          data.forEach((el) => {
            this.xMoneyInstutiondata.push(el.times);
            this.yMoneyInstutiondata.push(Number(el.nums));
          });

          // debugger
          // console.log(this.xInstutiondata)
          // data1.forEach((el) => {
          //   this.yInstutiondata2.push(Number(el.num));
          // });

          // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例  myChart1
          let myChart = echarts.init(document.getElementById('myChart6'));
          var option = {
            title: {
              text: '佣金收入概览'
            },
            tooltip: {
              trigger: 'item',
              triggerOn: "mousemove",
            },
            legend: {
              data: ['佣金收入概览']
            },


            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: this.xMoneyInstutiondata
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                name: '佣金收入',
                type: 'line',
                stack: 'Total',
                data: this.yMoneyInstutiondata
              }
            ]
          };
          // 绘制图表
          // myChart.setOption(option);
          option && myChart.setOption(option);
        }
      });

    },

    drawLine2() {
      getRuioDataByDay().then(res => {
        this.dataList = [];
        if (res.data) {
          this.dataList = res.data;//  每日总量
          var chartDom = document.getElementById('myChart1');
          var myChart = echarts.init(chartDom);
          var option;

          option = {
            tooltip: {
              trigger: 'item'
            },
            legend: {
              top: '5%',
              left: 'center'
            },
            series: [
              {
                name: '数据值',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                padAngle: 5,
                itemStyle: {
                  borderRadius: 10
                },
                label: {
                    show: true,
                    position: 'inner',
                    formatter: '{c}'


                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: 20,
                    fontWeight: 'bold'
                  }
                },
                labelLine: {
                  show: true
                },
                data: this.dataList
              }
            ]
          };

          option && myChart.setOption(option);

          //1111
        }
      });
    },
    drawLine3() {
      getRechargePal().then(res => {
        this.rechargeData = [];
        if (res.data) {
          this.rechargeData = res.data;//  每日总量
          var chartDom = document.getElementById('myChart3');
          var myChart = echarts.init(chartDom);
          var option;
          option = {
            tooltip: {
              trigger: 'item'
            },
            legend: {
              top: '5%',
              left: 'center'
            },
            series: [
              {
                name: '充值数',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                padAngle: 5,
                itemStyle: {
                  borderRadius: 10
                },
                label: {
                  show: true,
                  position: 'inner',
                  formatter: '{c}'
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: 20,
                    fontWeight: 'bold'
                  }
                },
                labelLine: {
                  show: false
                },
                data: this.rechargeData
              }
            ]
          };

          option && myChart.setOption(option);
        }
      });
    },


    drawLine7() {
      getDisRechargePal().then(res => {
        this.disRechargeData = [];
        if (res.data) {
          this.disRechargeData = res.data;//  每日总量
          var chartDom = document.getElementById('myChart7');
          var myChart = echarts.init(chartDom);
          var option;
          option = {
            tooltip: {
              trigger: 'item'
            },
            legend: {
              top: '5%',
              left: 'center'
            },
            series: [
              {
                name: '人数',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                padAngle: 5,
                itemStyle: {
                  borderRadius: 10
                },
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: 20,
                    fontWeight: 'bold'
                  }
                },
                labelLine: {
                  show: false
                },
                data: this.disRechargeData
              }
            ]
          };

          option && myChart.setOption(option);
        }
      });
    },


    drawLine8() {
      getMoneyRechargePal().then(res => {
        this.moneyRechargeData = [];
        if (res.data) {
          this.moneyRechargeData = res.data;//  每日总量
          var chartDom = document.getElementById('myChart8');
          var myChart = echarts.init(chartDom);
          var option;
          option = {
            tooltip: {
              trigger: 'item'
            },
            legend: {
              top: '5%',
              left: 'center'
            },
            series: [
              {
                name: '人数',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                padAngle: 5,
                itemStyle: {
                  borderRadius: 10
                },
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: 20,
                    fontWeight: 'bold'
                  }
                },
                labelLine: {
                  show: false
                },
                data: this.moneyRechargeData
              }
            ]
          };

          option && myChart.setOption(option);
        }
      });
    },




    drawLine4() {
      //获取机构概览
      getBuyVipData().then(res => {
        this.xInstutiondata4 = [];
        this.yInstutiondata4 = [];
        this.yInstutiondata4s = [];
        if (res.data) {
          const data = res.data.orderNum;//  订单量
          data.forEach((el) => {
            this.xInstutiondata4.push(el.times);
            this.yInstutiondata4.push(Number(el.nums));
          });
          res.data.orderMoney.forEach((el) => {
            // this.xInstutiondata4.push(el.times);
            this.yInstutiondata4s.push(Number(el.moneys));
          });
          // debugger
          // console.log(this.xInstutiondata)
          // data1.forEach((el) => {
          //   this.yInstutiondata2.push(Number(el.num));
          // });

          // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例  myChart1
          let myChart4 = echarts.init(document.getElementById('myChart4'));
          var option = {
            title: {
              text: '  会员订阅概览'
            },
            tooltip: {
              trigger: 'item',
              triggerOn: "mousemove",
            },
            legend: {
              data: ['订阅收入', '订阅数']
            },


            grid: {
              left: '3%',
              right: '7%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: this.xInstutiondata4
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                name: '订阅数',
                type: 'line',
                stack: 'Total',
                data: this.yInstutiondata4
              },
              {
                name: '订阅收入',
                type: 'line',
                stack: 'Total',
                data: this.yInstutiondata4s
              }
            ]
          };
          // 绘制图表
          // myChart.setOption(option);
          option && myChart4.setOption(option);
        }
      });
    }

  },
}
</script>

<style lang="less" scoped>
@import "./index.less";

blockquote {
  padding: 0 1em;
  color: #6a737d;
  border-left: 0.25em solid #dfe2e5;
}

.project-list {

  .card-title {
    font-size: 0;

    a {
      color: rgba(0, 0, 0, 0.85);
      margin-left: 12px;
      line-height: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: top;
      font-size: 14px;

      &:hover {
        color: #1890ff;
      }
    }
  }

  .card-description {
    color: rgba(0, 0, 0, 0.45);
    height: 66px;
    line-height: 22px;
    overflow: hidden;
  }

  .project-item {
    display: flex;
    margin-top: 8px;
    overflow: hidden;
    font-size: 12px;
    height: 20px;
    line-height: 20px;

    a {
      color: rgba(0, 0, 0, 0.45);
      display: inline-block;
      flex: 1 1 0;

      &:hover {
        color: #1890ff;
      }
    }

    .download {
      color: rgba(0, 0, 0, 0.25);
      flex: 0 0 auto;
      float: right;
    }
  }

  .ant-card-meta-description {
    color: rgba(0, 0, 0, 0.45);
    height: 44px;
    line-height: 22px;
    overflow: hidden;
  }
}

.item-group {
  padding: 20px 0 8px 24px;
  font-size: 0;

  a {
    color: rgba(0, 0, 0, 0.65);
    display: inline-block;
    font-size: 14px;
    margin-bottom: 13px;
    width: 25%;
  }
}

.mobile {

  .project-list {

    .project-card-grid {
      width: 100%;
    }
  }

  .more-info {
    border: 0;
    padding-top: 16px;
    margin: 16px 0 16px;
  }

  .headerContent .title .welcome-text {
    display: none;
  }
}

</style>
